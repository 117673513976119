import React from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Contact = props => (
  <Layout bodyClass="page-contact contact-page has-background-black has-text-white">
    <SEO
      title="Artists' Choice - Wisconsin's Custom Frame Shop"
      description="We offer quality and affordable custom framing in-person, by phone, or online!"
      />
    <div className="container has-text-centered">
    <div className="container pt-4 pb-4"><h1>Contact Artists' Choice</h1></div>
    <p>Please click the 'Start' button to fill out our contact form.</p>
        <div className="form-block">
          <ReactTypeformEmbed
              url="https://demo.typeform.com/to/MZpGSK"
              buttonText= "Click Here To Contact By Email"
              mode= "drawer_left"
          />
        </div>

    </div>
  </Layout>
);

export default Contact;
